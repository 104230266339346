<template>
    <div class="h-auto w-full overflow-auto no-scrollbar pb-10">
        <pipeline-opportunities :mobile="true" class="bg-filters" />
        <pipeline-sales :mobile="true" />
        <effectiveness-ratio :isMobile="true" />
    </div>
</template>

<script>

const EffectivenessRatio = () => import('@/components/Mobile/Pipeline/PipelineEffectiveness.vue');
const PipelineSales = () => import('@/components/Mobile/Pipeline/PipelineSales.vue')
const PipelineOpportunities = () => import('@/components/Mobile/Pipeline/PipelineOpportunities.vue')


export default  {
    components: {
        EffectivenessRatio,
        PipelineSales,
        PipelineOpportunities
    }
}
</script>
